import React from 'react';

interface ButtonProps {
  label: string;
  className?: string;
  onClick: (input: any) => void;
  isDisabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ label, className, onClick, isDisabled }) => {
  return (
    <button
      key={`btn-${label}`}
      className={`
        px-8 py-3 text-sm rounded-full
        transition-colors duration-150 ease-in-out
        focus:outline-none
        text-white
        bg-[#1c1c1c]
        hover:bg-blue-600
        ${isDisabled ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''}
        ${className}
      `}
      onClick={onClick}
      disabled={isDisabled}
    >
      {label}
    </button>
  );
};

export default Button;
