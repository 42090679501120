import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="64" viewBox="0 0 96 32" fill="none">
          {/* Background (static) logo */}
          <path
            d="M16.098 0H7.754v7.675h8.344zM7.755 19.013H0V32h7.755zm0-11.338H0v4.152h7.755zM16.098 11.827H7.754v7.196h8.344zm13.4-4.152h13.475v2.257H31.908v6.121h8.676v2.257h-8.676v7.984h-2.41zm15.006 0h2.271v18.63h-2.27zm4.381 12.105c0-4.334 2.485-7.027 5.87-7.027 3.856 0 6.234 3.247 5.73 7.558h-9.35c.128 3.322 1.981 4.386 3.834 4.386 2.25 0 3.192-1.703 3.535-2.555l2.088.724c-.985 2.683-3.213 3.811-5.698 3.811-3.63 0-6.009-2.427-6.009-6.898Zm9.265-1.353c0-2.502-1.585-3.8-3.406-3.8-1.746 0-3.374 1.405-3.567 3.8zm7.07 1.139-4.285-6.44h2.656l1.446 2.256a164 164 0 0 1 1.607 2.577c.375-.533.696-1.012 1.713-2.577l1.447-2.256h2.517l-4.338 6.461 4.605 6.707h-2.73l-1.661-2.502c-.986-1.49-1.285-1.937-1.66-2.533l-1.693 2.533-1.692 2.502h-2.485zm8.675-11.891h2.41v2.875h-2.41zm.086 5.45h2.27v13.17h-2.27zm5.131-5.45h2.27v18.63h-2.27zm4.381 12.105c0-4.334 2.485-7.027 5.87-7.027 3.855 0 6.233 3.247 5.73 7.558h-9.34c.128 3.322 1.981 4.386 3.834 4.386 2.25 0 3.192-1.703 3.535-2.555l2.089.724c-.986 2.683-3.214 3.811-5.699 3.811-3.63 0-6.02-2.427-6.02-6.898Zm9.265-1.353c0-2.502-1.585-3.8-3.406-3.8-1.736 0-3.374 1.405-3.556 3.8z"
            fill="#e0e0e0"
          />
          {/* Animated (filling) logo */}
          <path
            d="M16.098 0H7.754v7.675h8.344zM7.755 19.013H0V32h7.755zm0-11.338H0v4.152h7.755zM16.098 11.827H7.754v7.196h8.344zm13.4-4.152h13.475v2.257H31.908v6.121h8.676v2.257h-8.676v7.984h-2.41zm15.006 0h2.271v18.63h-2.27zm4.381 12.105c0-4.334 2.485-7.027 5.87-7.027 3.856 0 6.234 3.247 5.73 7.558h-9.35c.128 3.322 1.981 4.386 3.834 4.386 2.25 0 3.192-1.703 3.535-2.555l2.088.724c-.985 2.683-3.213 3.811-5.698 3.811-3.63 0-6.009-2.427-6.009-6.898Zm9.265-1.353c0-2.502-1.585-3.8-3.406-3.8-1.746 0-3.374 1.405-3.567 3.8zm7.07 1.139-4.285-6.44h2.656l1.446 2.256a164 164 0 0 1 1.607 2.577c.375-.533.696-1.012 1.713-2.577l1.447-2.256h2.517l-4.338 6.461 4.605 6.707h-2.73l-1.661-2.502c-.986-1.49-1.285-1.937-1.66-2.533l-1.693 2.533-1.692 2.502h-2.485zm8.675-11.891h2.41v2.875h-2.41zm.086 5.45h2.27v13.17h-2.27zm5.131-5.45h2.27v18.63h-2.27zm4.381 12.105c0-4.334 2.485-7.027 5.87-7.027 3.855 0 6.233 3.247 5.73 7.558h-9.34c.128 3.322 1.981 4.386 3.834 4.386 2.25 0 3.192-1.703 3.535-2.555l2.089.724c-.986 2.683-3.214 3.811-5.699 3.811-3.63 0-6.02-2.427-6.02-6.898Zm9.265-1.353c0-2.502-1.585-3.8-3.406-3.8-1.736 0-3.374 1.405-3.556 3.8z"
            fill="#4d4d4d"
          >
            <animate
              attributeName="fill-opacity"
              values="0;1;0"
              dur="3s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    </div>
  );
};

export default Loading;
