import { useLocation } from 'react-router-dom'
import Button from 'components/Button'
import { useModal } from './useModal'

export const usePageActions = () => {
  const location = useLocation()
  const { openModal, setAction } = useModal()

  const getPageActions = (path: string) => {
    switch (path) {
      case '/calendar':
        return (
          <>
            <Button
              label="New event"
              onClick={() => {
                setAction('create')
                openModal()
              }}
            />
          </>
        )
      default:
        return null
    }
  }

  return [getPageActions(location.pathname)]
}
