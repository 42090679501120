import { useLocation } from 'react-router-dom';

export const usePageTitle = () => {
  const location = useLocation();

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getPageTitle = (path: string) => {
    const pageName = path.split('/').at(-1);
    switch (path) {
      case '/':
        return 'Antiwork';
      default:
        return pageName ? capitalizeFirstLetter(pageName) : '404';
    }
  };

  return [getPageTitle(location.pathname)];
};
