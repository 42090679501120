import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  UsersIcon,
  ArrowRightStartOnRectangleIcon,
  UserIcon,
  CalendarIcon
} from '@heroicons/react/24/outline'
import { useAuth } from '../hooks/useAuth'

const Sidebar: React.FC = () => {
  const location = useLocation()
  const { logout } = useAuth()

  const navItems = [
    { name: 'People', href: '/people', icon: UsersIcon },
    { name: 'Calendar', href: '/calendar', icon: CalendarIcon }
  ]

  return (
    <div className="bg-[#1c1c1c] text-gray-400 w-60 h-screen flex flex-col pl-2 pt-1">
      {/* Flexile Logo */}
      <Link to="/" className="p-4 mt-3">
        <img src="/flexile-logo.svg" alt="Flexile" className="h-8 invert" />
      </Link>

      {/* Navigation */}
      <nav className="mt-4">
        {/* Special Antiwork Section */}
        <Link to="/" className="px-4 py-2 mb-1 flex items-center space-x-3">
          <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center pb-0.5">
            <span
              className="text-[#1c1c1c] text-s font-['Major_Mono_Display'] font-bold"
              style={{ textShadow: '0 0 0.5px #1c1c1c' }}
            >
              A
            </span>
          </div>
          <span className="text-white font-medium text-sm">Antiwork</span>
        </Link>

        {navItems.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className={`flex items-center space-x-3 px-4 py-2 hover:text-white transition-colors duration-200 ease-in-out ${
              location.pathname === item.href ? 'text-white' : ''
            }`}
          >
            <item.icon
              className={`h-5 w-5 ${
                location.pathname === item.href ? 'text-white' : ''
              }`}
            />
            <span className="text-sm">{item.name}</span>
          </Link>
        ))}
      </nav>

      <div className="mt-auto">
        {/* Profile Section */}
        <Link
          to="/profile"
          className={`flex items-center space-x-3 px-4 py-2 hover:text-white transition-colors duration-200 ease-in-out ${
            location.pathname === '/profile' ? 'text-white' : ''
          }`}
        >
          <UserIcon className="h-5 w-5" />
          <span className="text-sm">Profile</span>
        </Link>

        {/* Log out */}
        <Link
          to="/logout"
          onClick={logout}
          className="flex items-center space-x-3 px-4 py-2 mb-4 hover:text-white transition-colors duration-200 ease-in-out"
        >
          <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
          <span className="text-sm">Log out</span>
        </Link>
      </div>
    </div>
  )
}

export default Sidebar
