import React, { useState } from 'react'
import TabNavigation from '../components/TabNavigation'
import { usePeople } from 'hooks/usePeople'
import { formatName } from 'utils/util'
import { useEvents } from 'hooks/useEvents'

const People: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Active')
  const { onboarding, active, alumni } = usePeople()
  const { today } = useEvents()

  const peopleData = { onboarding, active, alumni }
  const filteredPeople = peopleData[
    activeTab.toLowerCase() as keyof typeof peopleData
  ] as any[]

  const getEmptyMessage = (tab: string) => {
    switch (tab.toLowerCase()) {
      case 'onboarding':
        return 'There are no onboarding contractors at the moment.'
      case 'active':
        return 'There are no active contractors at the moment.'
      case 'alumni':
        return 'There are no past contractors at the moment.'
      default:
        return 'No results found.'
    }
  }

  const tabs = ['Onboarding', 'Active', 'Alumni']

  const vacationPeopleIds = filteredPeople.filter((person) => today.some((event) => event.userId === person._id)).map(person => person._id)
  const isVacationColumn = vacationPeopleIds.length > 0

  return (
    <div className="overflow-x-hidden">
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />

      <div className="w-full border-2 border-gray-200 rounded-2xl overflow-hidden">
        {filteredPeople.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {isVacationColumn && (
                    <th
                      scope="col"
                      className="text-center text-sm font-normal text-gray-500"
                    ></th>
                  )}
                  <th
                    scope="col"
                    className="pl-4 pr-6 py-3 text-left text-sm font-normal text-gray-500 w-1/5"
                  >
                    Contractor
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-normal text-gray-500 w-1/6"
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-normal text-gray-500 w-1/6"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-sm font-normal text-gray-500 w-1/6"
                  >
                    Average hours
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-sm font-normal text-gray-500 w-1/6"
                  >
                    Rate
                  </th>
                  <th
                    scope="col"
                    className="pl-6 pr-4 py-3 text-right text-sm font-normal text-gray-500 w-1/6"
                  >
                    Avg. Year
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredPeople.map((person, index) => (
                  <tr key={index}>
                    {isVacationColumn && (
                      <td className="pl-4 py-0 whitespace-nowrap text-sm text-gray-900 text-center">
                        {vacationPeopleIds.includes(person._id) && '🌴'}
                      </td>
                    )}
                    <td className="pl-4 pr-6 py-4 whitespace-nowrap overflow-hidden">
                      <div className="text-sm font-medium text-gray-900 truncate">
                        {formatName(person.name)}
                      </div>
                      <div className="text-xs text-gray-500 truncate">
                        {person.role}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap truncate text-sm text-gray-900">
                      {person.country || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap truncate text-sm text-gray-900">
                      {person.startDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap truncate text-sm text-gray-900 text-right">
                      {person.avgHours || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap truncate text-sm text-gray-900 text-right">
                      ${person.rate || 'N/A'}
                    </td>
                    <td className="pl-6 pr-4 py-4 whitespace-nowrap truncate text-sm text-gray-900 text-right">
                      ${person.avgYear?.toLocaleString() || 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full py-8 flex items-center justify-center bg-gray-50">
            <p className="text-gray-500 text-sm">
              {getEmptyMessage(activeTab)}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default People
