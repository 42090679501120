import React, { useCallback } from 'react'
import { format, isSameMonth } from 'date-fns'
import { useEvents } from './useEvents'
import { DateHeaderProps } from 'react-big-calendar'
import { useModal } from './useModal'
import { useAuth } from './useAuth'

const CUSTOM_BLUE = '#2563eb' // Custom blue color

interface Event {
  id: number
  title: string
  start: Date
  end: Date
  userId?: string
  allDay?: boolean
  resource?: any
}

export const useCalendar = () => {
  const { events } = useEvents()
  const { user } = useAuth()
  const { openModal, setAction, setProps } = useModal()

  const lastEvent = events.sort(
    (a, b) => a.start.getTime() - b.start.getTime()
  )[events.length - 1]

  // Custom Agenda component
  const CustomAgenda = (obj: any) => {
    let className = ''
    const date = new Date(
      obj.day.getFullYear(),
      obj.day.getMonth(),
      obj.day.getDate()
    )

    const start = new Date(
      lastEvent.start.getFullYear(),
      lastEvent.start.getMonth(),
      lastEvent.start.getDate()
    )
    const end = new Date(
      lastEvent.end.getFullYear(),
      lastEvent.end.getMonth(),
      lastEvent.end.getDate()
    )

    if (start.getTime() !== end.getTime()) {
      if (date.getTime() === end.getTime()) {
        className = 'first-of-last'
      }
    } else if (date.getTime() === start.getTime()) {
      className = 'first-of-last'
    }
    return <span className={className}>{obj.label}</span>
  }

  const CustomDateHeader: React.FC<DateHeaderProps> = ({ label }) => {
    return <>{`${Number(label)}`}</>
  }

  const components = {
    toolbar: () => null,
    month: {
      dateHeader: CustomDateHeader
    },
    agenda: {
      date: CustomAgenda
    },
    eventWrapper: (props: any) => {
      const { event, children } = props
      const isUserEvent = event.userId === user?.id

      return React.cloneElement(React.Children.only(children), {
        style: {
          ...children.props.style,
          backgroundColor: isUserEvent ? CUSTOM_BLUE : 'gray',
          borderColor: isUserEvent ? CUSTOM_BLUE : 'gray',
          cursor: isUserEvent ? 'pointer' : 'default'
        }
      })
    },
    dateCellWrapper: (props: any) => {
      const { children, value, range } = props;
      const isCurrentMonth = isSameMonth(value, range.start);
      return React.cloneElement(React.Children.only(children), {
        className: `${children.props.className} ${isCurrentMonth ? 'rbc-current-month-cell' : 'rbc-off-range-cell'}`,
      });
    }
  }

  const formats = {
    agendaDateFormat: (date: Date) => format(date, 'MMMM d, yyyy'),
    agendaHeaderFormat: ({ start, end }: any) =>
      `${format(start, 'MMMM d, yyyy')} - ${format(end, 'MMMM d, yyyy')}`
  }

  const handles = {
    onSelectSlot: (obj: any) => {
      setAction('create')
      setProps({ start: obj.start, end: new Date(obj.end.getTime() - 1) })
      openModal()
    },
    onSelectEvent: useCallback(
      (event: Event) => {
        const isUserEvent = event.userId === user?.id
        if (isUserEvent) {
          setAction('edit')
          setProps({
            title: event.title,
            start: event.start,
            end: event.end,
            id: event.id
          })
          openModal()
        }
      },
      [openModal, setAction, setProps, user?.id]
    ),
    getDrilldownView: useCallback(() => null, [])
  }

  return { components, formats, handles }
}
