import React from 'react'
import { useAuth } from '../hooks/useAuth'

interface GoogleLoginButtonProps {
  onSuccess: (response: any) => void
}

const GOOGLE_CLIENT_ID =
  '216800052230-8dbhirrthsn9p5a6fmcse7gsajpq2l6c.apps.googleusercontent.com'
const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth'
const BASE_URL = process.env.REACT_APP_BASE_URL

const handleGoogleLogin = (onSuccess: (response: any) => void) => {
  const redirectUri = `${BASE_URL}/auth/google/callback`
  const scope = 'email profile'
  const responseType = 'token'

  const authUrl = `${GOOGLE_AUTH_URL}?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&scope=${encodeURIComponent(
    scope
  )}&response_type=${responseType}&prompt=consent`

  const width = 500
  const height = 600
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2

  const authWindow = window.open(
    authUrl,
    'GoogleAuth',
    `width=${width},height=${height},top=${top},left=${left}`
  )

  const handleMessage = async (event: MessageEvent) => {
    if (event.origin !== window.location.origin) return

    if (event.data?.type === 'GOOGLE_AUTH_SUCCESS') {
      if (authWindow) authWindow.close()
      try {
        if (event.data.access_token) {
          onSuccess(event.data)
        } else {
          console.error('No access token received from Google')
        }
      } catch (error) {
        console.error('Google login failed:', error)
      }
      window.removeEventListener('message', handleMessage)
    }
  }

  window.addEventListener('message', handleMessage)
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onSuccess }) => {
  return (
    <button
      onClick={() => handleGoogleLogin(onSuccess)}
      className="w-full flex items-center justify-center px-4 py-2 border text-m rounded-3xl border-gray-500 text-gray-700 bg-white hover:border-[#006ceb] hover:text-[#006ceb]"
    >
      <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
        <path
          fill="#4285F4"
          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
        />
        <path
          fill="#34A853"
          d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
        />
        <path
          fill="#FBBC05"
          d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
        />
        <path
          fill="#EA4335"
          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
        />
        <path fill="none" d="M1 1h22v22H1z" />
      </svg>
      Continue with Google
    </button>
  )
}

export default GoogleLoginButton
