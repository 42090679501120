import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import GoogleLoginButton from '../components/GoogleLoginButton'

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { login, isAuthenticated, user } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    // try {
    //   await login('email', { email, password })
    //   // No need to navigate here, the useEffect will handle it
    // } catch (error) {
    //   console.error('Login failed:', error)
    // }
  }

  const handleGoogleLoginSuccess = async (response: any) => {
    try {
      await login('google', response.access_token)
      // No need to navigate here, the useEffect will handle it
    } catch (error) {
      console.error('Google login failed:', error)
    }
  }

  const disabledInputClassName =
    'border-gray-200 bg-gray-100 text-gray-300 cursor-not-allowed'
  const disabledLabelClassName = 'text-gray-300 cursor-not-allowed'
  const disabledButtonClassName = 'bg-gray-100 text-gray-300 cursor-not-allowed'

  const formClassName = `mt-8 rounded-md border-0`
  const inputClassName = `w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 ${disabledInputClassName}`

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="px-8 py-6 mt-4 text-left bg-white min-w-500">
        <h3 className="text-3xl font-bold text-center">Log into Flexile</h3>
        <form onSubmit={handleSubmit} className={formClassName}>
          <div>
            <div>
              <label
                className={`block ${disabledLabelClassName}`}
                htmlFor="email"
              >
                Email
              </label>
              <input
                id="email"
                type="text"
                className={inputClassName}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                disabled
              />
            </div>
            <div className="mt-4">
              <div className="flex items-baseline justify-between">
                <label
                  className={`block ${disabledLabelClassName}`}
                  htmlFor="password"
                >
                  Password
                </label>
                <a
                  href="/"
                  className={`text-blue-600 underline ${disabledLabelClassName}`}
                >
                  Forgot?
                </a>
              </div>
              <input
                id="password"
                type="password"
                className={inputClassName}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                disabled
              />
            </div>
            <div className="flex items-baseline justify-between">
              <button
                disabled
                className={`px-6 py-2 mt-5 bg-blue-600 rounded-3xl w-full  ${disabledButtonClassName}`}
              >
                Log in
              </button>
            </div>
          </div>
        </form>

        {/* Line separator with "OR" */}
        <div className="flex items-center my-5 mx-3">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="flex-shrink mx-4 text-[#1c1c1c]">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div className="w-full">
          <GoogleLoginButton onSuccess={handleGoogleLoginSuccess} />
        </div>
      </div>
    </div>
  )
}

export default Login
