import React, { useEffect, useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';

interface ToastProps {
  label: string;
  type: 'success' | 'error';
}

const Toast: React.FC<ToastProps> = ({ label, type }) => {
  const [isVisible, setIsVisible] = useState(false);
  const bgColor = type === 'success' ? 'bg-green-700' : 'bg-red-600';
  const Icon = type === 'success' ? CheckIcon : XMarkIcon;

  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => setIsVisible(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`
        fixed top-10 left-60 right-10 z-50
        flex justify-center items-center
        py-2 px-2
        transition-opacity duration-300 ease-in-out
        ${isVisible ? 'opacity-100' : 'opacity-0'}
      `}
    >
      <div
        className={`
          px-5 py-3 text-sm rounded-full
          flex items-center space-x-2
          text-white
          ${bgColor}
          transition-transform duration-300 ease-in-out
          ${isVisible ? 'transform translate-y-0' : 'transform -translate-y-full'}
        `}
      >
        <div className="bg-white rounded-full p-1">
          <Icon className={`h-2 w-2 ${type === 'success' ? 'text-green-700' : 'text-red-500'}`} />
        </div>
        <span>{label}</span>
      </div>
    </div>
  );
};

export default Toast;
