import { useLocation } from 'react-router-dom'
// import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export const usePageSearch = () => {
  const location = useLocation()

  // const search = <div className="relative mb-4">
  //   <input
  //     type="text"
  //     placeholder="Search invoices, people..."
  //     className="w-full bg-white rounded-full py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-gray-300 text-sm"
  //   />
  //   <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
  // </div>

  const getPageSearch = (path: string) => {
    switch (path) {
      // case '/people':
      //   return search;
      default:
        return null
    }
  }

  return [getPageSearch(location.pathname)]
}
