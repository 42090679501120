import React, { useState } from 'react';

interface TabNavigationProps {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({ tabs, activeTab, onTabChange }) => {
  const [hoveredTab, setHoveredTab] = useState<string | null>(null);

  return (
    <nav className="flex mb-6">
      {tabs.map((tab) => (
        <button
          key={`nav-${tab}`}
          className={`
            px-4 py-2 text-sm rounded-full mr-1
            transition-colors duration-150 ease-in-out
            focus:outline-none
            ${activeTab === tab
              ? hoveredTab && hoveredTab !== tab
                ? 'bg-gray-300'
                : 'bg-blue-600 text-white' // Active tab normally
              : hoveredTab === tab
                ? 'bg-blue-600 text-white' // Hovered non-active tab
                : 'text-gray-900 hover:text-blue-600' // Non-active tab
            }
          `}
          onClick={() => onTabChange(tab)}
          onMouseEnter={() => setHoveredTab(tab)}
          onMouseLeave={() => setHoveredTab(null)}
        >
          {tab}
        </button>
      ))}
    </nav>
  );
};

export default TabNavigation;
