import React, { createContext, useState, useEffect, ReactNode } from 'react'
import { apiRequest } from 'utils/api'

interface AuthContextType {
  isAuthenticated: boolean
  login: (method: 'email' | 'google', credentials: any) => Promise<void>
  logout: () => void
  user: any
  isLoading: boolean
}

export const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
  children: ReactNode
}

const RUBY_API_BASE_URL = process.env.REACT_APP_RUBY_API_BASE_URL

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token')
      if (token && !isAuthenticated) {
        !isLoading && setIsLoading(true)
        const data = await apiRequest<{ user: any }>({
          baseUrl: RUBY_API_BASE_URL,
          method: 'POST',
          url: '/auth/verify',
          data: { token }
        })

        if (data) {
          !isAuthenticated && setIsAuthenticated(true)
          !user && setUser(data.user)
        } else {
          localStorage.removeItem('token')
          isAuthenticated && setIsAuthenticated(false)
          user && setUser(null)
          console.error('Invalid token')
        }
      }
      setIsLoading(false)
    }

    checkAuth()
  }, [])

  const login = async (method: 'email' | 'google', token: any) => {
    !isLoading && setIsLoading(true)
    let data
    if (method === 'email') {
      // Implement your email login logic here
    } else if (method === 'google') {
      data = await apiRequest<{ token: string; user: any }>({
        baseUrl: RUBY_API_BASE_URL,
        method: 'POST',
        url: '/auth/google',
        data: { token }
      })
    }

    if (data) {
      localStorage.setItem('token', data.token)
      setIsAuthenticated(true)
      setUser(data.user)
    } else {
      setIsAuthenticated(false)
      setUser(null)
    }
    setIsLoading(false)
  }

  const logout = () => {
    localStorage.removeItem('token')
    setIsAuthenticated(false)
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, user, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  )
}
