import React from 'react'

interface HeaderProps {
  title: string
  search: React.ReactNode
  actions: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({ title, search, actions }) => {
  return (
    <header className="bg-gray-200 py-3 px-6 pt-6 pb-5 border-b-2 border-gray-300">
      <div className="mx-10">
        {search}
        <div className="flex justify-between items-center pt-3 pb-1 min-h-60px">
          <h1 className="text-2xl font-bold">{title}</h1>
          <div className="flex space-x-2">{actions}</div>
        </div>
      </div>
    </header>
  )
}

export default Header
