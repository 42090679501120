import React from 'react'
import { useAuth } from '../hooks/useAuth'
import { useEvents } from 'hooks/useEvents'
import { usePeople } from 'hooks/usePeople'
import { Link } from 'react-router-dom'
import { formatName } from 'utils/util'

const Home: React.FC = () => {
  const { user } = useAuth()
  const { today, past, future } = useEvents()
  const { onboarding, active, alumni } = usePeople()

  const splanClassName = 'font-semibold'

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-1xl">
          Hi,{' '}
          <Link to={`/profile`} className={`${splanClassName} text-[#006DE4]`}>
            {formatName(user?.name)}
          </Link>
          ! Welcome to <span className={splanClassName}>FlexileClone</span>.
        </h1>
      </div>

      <div className="space-y-6 my-10">
        <div>
          <h2 className="text-lg font-semibold mb-4">Contractors</h2>
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-white p-4 rounded-lg border-2 border-gray-200 flex flex-col justify-between">
              <p className="text-xl font-bold">{onboarding.length}</p>
              <p className="text-xs text-gray-600">Onboarding</p>
            </div>
            <div className="p-4 rounded-lg border-2 flex flex-col justify-between border-[#006DE4] bg-[#E6F2FF]">
              <p className="text-xl font-bold">{active.length}</p>
              <p className="text-xs text-gray-600">Active</p>
            </div>
            <div className="bg-white p-4 rounded-lg border-2 border-gray-200 flex flex-col justify-between">
              <p className="text-xl font-bold">{alumni.length}</p>
              <p className="text-xs text-gray-600">Alumni</p>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-4">Events</h2>
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-white p-4 rounded-lg border-2 border-gray-200 flex flex-col justify-between">
              <p className="text-xl font-bold">{past.length}</p>
              <p className="text-xs text-gray-600">Past</p>
            </div>
            <div className="p-4 rounded-lg border-2 flex flex-col justify-between border-[#006DE4] bg-[#E6F2FF]">
              <p className="text-xl font-bold">{today.length}</p>
              <p className="text-xs text-gray-600">Today</p>
            </div>
            <div className="bg-white p-4 rounded-lg border-2 border-gray-200 flex flex-col justify-between">
              <p className="text-xl font-bold">{future.length}</p>
              <p className="text-xs text-gray-600">Future</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
