import Toast from 'components/Toast'
import React, { createContext, useState, useCallback, ReactNode } from 'react'

export interface ToastContextProps {
  showToast: (message: string, type: 'success' | 'error') => void
}

interface ToastState {
  message: string
  type: 'success' | 'error'
  isVisible: boolean
}

export const ToastContext = createContext<ToastContextProps | undefined>(
  undefined
)

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [toast, setToast] = useState<ToastState>({
    message: '',
    type: 'success',
    isVisible: false
  })

  const showToast = useCallback(
    (message: string, type: 'success' | 'error') => {
      setToast({ message, type, isVisible: true })
      setTimeout(() => {
        setToast((prevToast) => ({ ...prevToast, isVisible: false }))
      }, 2000)
    },
    []
  )

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast.isVisible && <Toast label={toast?.message} type={toast?.type} />}
    </ToastContext.Provider>
  )
}
