import React, { Suspense, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import Layout from './components/Layout'
import Login from './pages/Login'
import { useAuth } from './hooks/useAuth'
import Home from './pages/Home'
import People from './pages/People'
import Profile from './pages/Profile'
import Calendar from './pages/Calendar'
import Loading from './components/Loading'
import GoogleAuthCallback from './components/GoogleAuthCallback'
import { PeopleProvider } from 'contexts/PeopleContext'
import { EventProvider } from 'contexts/EventContext'
import { useEvents } from 'hooks/useEvents'
import { usePeople } from 'hooks/usePeople'
import MobileMessage from './components/MobileMessage'

const AuthenticatedRoutes: React.FC = () => {
  const { isLoading: eventsLoading } = useEvents()
  const { isLoading: peopleLoading } = usePeople()

  if (eventsLoading || peopleLoading) {
    return <Loading />
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/people" element={<People />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  )
}

const App: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false)
  const { isAuthenticated, isLoading } = useAuth()

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1150) // Adjust this breakpoint as needed
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)

    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  if (isMobile) {
    return <MobileMessage />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/" replace /> : <Login />}
          />
          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <EventProvider>
                  <PeopleProvider>
                    <AuthenticatedRoutes />
                  </PeopleProvider>
                </EventProvider>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/auth/google/callback"
            element={<GoogleAuthCallback />}
          />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
