import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

interface ApiRequestProps {
  baseUrl: string | undefined
  method: string
  url: string
  data?: any
  config?: AxiosRequestConfig
  setLoadingState?: boolean
}

export const apiRequest = async <T,>({
  baseUrl,
  method,
  url,
  data,
  config
}: ApiRequestProps): Promise<T | null> => {
  try {
    const response: AxiosResponse<T> = await axios({
      method,
      url: `${baseUrl}${url}`,
      data,
      ...config
    })

    return response.data
  } catch (err) {
    const errorMessage =
      err instanceof Error ? err.message : 'An unknown error occurred'

    console.error('API request failed:', errorMessage)
    return null
  }
}
