import React, { createContext, useState, ReactNode } from 'react';

export interface ModalContextProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  openModal: () => void;
  closeModal: () => void;
  action: 'create' | 'edit';
  setAction: (action: 'create' | 'edit') => void;
  props: ModalProps;
  setProps: (props: ModalProps) => void;
}

interface ModalProps {
  title?: string;
  id?: number;
  start: Date | null;
  end: Date | null;
}

export const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState<'create' | 'edit'>('create');
  const [props, setProps] = useState<ModalProps>({ title: '', start: null, end: null });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const value = {
    isModalOpen,
    setIsModalOpen,
    openModal,
    closeModal,
    action,
    setAction,
    props,
    setProps
  }

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};
