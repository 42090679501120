import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'
import { usePageTitle } from 'hooks/usePageTitle'
import { usePageSearch } from 'hooks/usePageSearch'
import { usePageActions } from 'hooks/usePageActions'

const Layout: React.FC = () => {
  const [title] = usePageTitle()
  const [search] = usePageSearch()
  const [actions] = usePageActions()

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-auto">
        <Header title={title} search={search} actions={actions} />
        <main className="flex-1 bg-white pt-5 pb-10 pl-16 pr-16">
          <div className="max-w-[your-desired-max-width] mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  )
}

export default Layout
