import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { TrashIcon } from '@heroicons/react/24/outline'
import Modal from './Modal'
import Button from './Button'
import { useAuth } from 'hooks/useAuth'
import { useModal } from 'hooks/useModal'
import { useEvents } from 'hooks/useEvents'
import { formatName } from 'utils/util'
import 'react-datepicker/dist/react-datepicker.css'
import { useToast } from 'hooks/useToast'

interface EventModalProps {
  isOpen: boolean
  onClose: () => void
  start: Date | null
  end: Date | null
}

const EventModal: React.FC<EventModalProps> = ({ isOpen, onClose }) => {
  const { action, props, setProps } = useModal()
  const { validateEvent, addEvent, editEvent, deleteEvent, mergeEvents } =
    useEvents()
  const [startDate, setStartDate] = useState<Date | null>(props.start)
  const [endDate, setEndDate] = useState<Date | null>(props.end)
  const [isFormValid, setIsFormValid] = useState(false)
  const { user } = useAuth()
  const { showToast } = useToast()

  const closeModal = () => {
    setProps({ title: '', start: null, end: null })
    onClose()
  }

  const title = props.title || `🌴 ${formatName(user.name)}`

  useEffect(() => {
    setStartDate(props.start)
    setEndDate(props.end)
  }, [props])

  useEffect(() => {
    setIsFormValid(!!startDate && !!endDate)
  }, [startDate, endDate])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (isFormValid) {
      const { error, merge } = validateEvent(props.id!, action, startDate!, endDate!)
      if (error) {
        showToast('Event creation failed', 'error')
      } else if (merge) {
        mergeEvents(merge.ids!, title, merge.start!, merge.end!)
      } else if (action === 'create') {
        addEvent(title, startDate!, endDate!)
      } else {
        editEvent(props.id!, title, startDate!, endDate!)
      }
      closeModal()
    }
  }

  const labelClassName = 'text-sm font-medium'
  const inputClassName =
    'border-2 border-gray-300 rounded p-2 focus:outline-none mt-2 mb-5 min-w-full'

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={action === 'create' ? 'New event' : 'Edit event'}
    >
      <form onSubmit={handleSubmit} className="flex flex-col text-sm">
        <label className={labelClassName}>Event title</label>
        <input
          type="text"
          value={title}
          readOnly
          required
          className={`${inputClassName} cursor-default bg-gray-100`}
        />
        <label className={labelClassName}>Start date</label>
        <DatePicker
          selected={startDate}
          onChange={(date: Date | null) => {
            setStartDate(date)
            if (date && endDate && date > endDate) {
              setEndDate(date)
            }
            if (date && !endDate) {
              setEndDate(date)
            }
          }}
          dateFormat="MMM d, yyyy"
          className={inputClassName}
          required
        />
        <label className={labelClassName}>End date</label>
        <DatePicker
          selected={endDate}
          onChange={(date: Date | null) =>
            setEndDate(date ? new Date(date.getTime() + 1) : null)
          }
          dateFormat="MMM d, yyyy"
          className={inputClassName}
          required
          minDate={startDate || undefined}
        />
        <div className="flex">
          <Button
            className="w-full"
            label={action === 'create' ? 'Create' : 'Save changes'}
            onClick={handleSubmit}
            isDisabled={!isFormValid}
          />
          {action === 'edit' && (
            <TrashIcon
              className="h-5 w-5 ml-4 self-center text-gray-500 hover:cursor-pointer hover:text-gray-800"
              onClick={() => {
                deleteEvent(props.id!)
                closeModal()
              }}
            />
          )}
        </div>
      </form>
    </Modal>
  )
}

export default EventModal
