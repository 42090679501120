import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import { useAuth } from '../hooks/useAuth'
import { useToast } from 'hooks/useToast'
import { apiRequest } from 'utils/api'

interface Event {
  id: number
  _id?: number
  title: string
  start: Date
  end: Date
  allDay?: boolean
  resource?: any
  userId?: string
}

interface EventContextType {
  isLoading: boolean
  events: Event[]
  today: Event[]
  past: Event[]
  future: Event[]
  addEvent: (title: string, start?: Date, end?: Date) => void
  editEvent: (id: number, title: string, start?: Date, end?: Date) => void
  deleteEvent: (id: number) => void
  mergeEvents: (ids: number[], title: string, start?: Date, end?: Date) => void
  validateEvent: (
    id: number,
    action: string,
    start: Date,
    end: Date
  ) => { error?: string; merge?: { ids?: number[]; start?: Date; end?: Date } }
}

const mapEvent = (event: Event) => ({
  ...event,
  id: event._id!,
  start: new Date(event.start),
  end: new Date(event.end)
})

export const EventContext = createContext<EventContextType | undefined>(
  undefined
)

const API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL

export const EventProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [events, setEvents] = useState<Event[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { user } = useAuth()
  const { showToast } = useToast()
  // const { handleLoading } = useApi()

  const userEvents = events.filter(({ userId }) => userId === user?.id)
  // .flatMap(({ start, end }) => getDaysArray(start, end))
  // .map((date) => date.getTime())

  const fetchEvents = useCallback(async () => {
    !isLoading && setIsLoading(true)
    const response = await apiRequest<Event[]>({
      baseUrl: API_BASE_URL,
      method: 'get',
      url: '/events',
      data: undefined,
      config: undefined,
      setLoadingState: false
    })

    if (response) {
      setEvents(response.map(mapEvent))
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  const diff = (s: Date, e: Date) =>
    Math.abs(
      new Date(s.getFullYear(), s.getMonth(), s.getDate()).getTime() -
        new Date(e.getFullYear(), e.getMonth(), e.getDate()).getTime()
    )
  const dayDiff = (s: Date, e: Date) => diff(s, e) / 1000 / 60 / 60 / 24

  const validateEvent = (id: number, action: string, start?: Date, end?: Date) => {
    // console.log({ id, action, start, end })
    const events = [...userEvents].map((event) => (event.id === id ? {
      ...event,
      start: new Date(start!),
      end: new Date(end!)
    } : event))

    const isNext = events.filter(event => dayDiff(event.start, end!) <= 1 || dayDiff(event.end, end!) <= 1 || dayDiff(event.start, start!) <= 1 || dayDiff(event.end, start!) <= 1)
    const isIncluded = events.filter(event => start!.getTime() <= event.start.getTime() && end!.getTime() >= event.end.getTime())
    const includes = events.filter(event => event.start.getTime() <= start!.getTime() && event.end.getTime() >= end!.getTime())
    // console.log({ isNext, isIncluded, includes })
    const toMerge = [...isNext, ...isIncluded, ...includes]
    // console.log({ toMerge })

    if (toMerge.length) {
      const toMergeIds = toMerge.map(({ id }) => id)
      const dates = [
        ...toMerge.flatMap(({ start, end }) => [start, end]),
        start!,
        end!
      ].sort((a, b) => a.getTime() - b.getTime())

      const mergeStart = dates[0]
      const mergeEnd = dates.at(-1)

      // console.log({ toMergeIds, mergeStart, mergeEnd })

      return { merge: { ids: toMergeIds, start: mergeStart, end: mergeEnd } }
    }

    return {}
  }

  const addEvent = useCallback(
    async (title: string, start?: Date, end?: Date) => {
      const response = await apiRequest<Event>({
        baseUrl: API_BASE_URL,
        method: 'post',
        url: '/events',
        data: {
          title,
          start,
          end,
          userId: user?.id
        },
        config: undefined,
        setLoadingState: false
      })

      if (response) {
        setEvents((prevEvents) => [...prevEvents, mapEvent(response)])
        showToast('Event created', 'success')
      } else {
        showToast('Event creation failed', 'error')
      }
    },
    [user, showToast]
  )

  const editEvent = useCallback(
    async (id: number, title: string, start?: Date, end?: Date) => {
      const response = await apiRequest<Event>({
        baseUrl: API_BASE_URL,
        method: 'put',
        url: `/events/${id}`,
        data: { title, start, end },
        config: undefined,
        setLoadingState: false
      })
      if (response) {
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === id ? mapEvent(response) : event
          )
        )
        showToast('Event saved', 'success')
      } else {
        showToast('Event update failed', 'error')
      }
    },
    [showToast]
  )

  const deleteEvent = useCallback(
    async (id: number) => {
      const response = await apiRequest<{ message: string }>({
        baseUrl: API_BASE_URL,
        method: 'delete',
        url: `/events/${id}`,
        data: undefined,
        config: undefined,
        setLoadingState: false
      })
      if (response) {
        setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id))
        showToast('Event deleted', 'success')
      } else {
        showToast('Event deletion failed', 'error')
      }
    },
    [showToast]
  )

  const mergeEvents = useCallback(
    async (ids: number[], title: string, start?: Date, end?: Date) => {
      const response = await apiRequest<Event>({
        baseUrl: API_BASE_URL,
        method: 'post',
        url: `/events/merge`,
        data: { ids, title, start, end, userId: user?.id },
        config: undefined,
        setLoadingState: false
      })
      if (response) {
        setEvents((prevEvents) => [
          ...prevEvents.filter((event) => !ids.includes(event.id)),
          mapEvent(response)
        ])
        showToast('Event saved', 'success')
      } else {
        showToast('Event creation failed', 'error')
      }
    },
    [showToast]
  )

  const contextValue = useMemo(() => {
    const now = new Date()
    const today = events.filter(
      (event: Event) => event.start < now && event.end > now
    )
    const past = events.filter((event: Event) => event.end < now)
    const future = events.filter((event: Event) => event.end > now)

    return {
      isLoading,
      events,
      today,
      past,
      future,
      validateEvent,
      addEvent,
      editEvent,
      deleteEvent,
      mergeEvents
    }
  }, [events, addEvent, editEvent, deleteEvent, validateEvent, isLoading])

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  )
}
