import React, { useEffect } from 'react'

const GoogleAuthCallback: React.FC = () => {
  useEffect(() => {
    const hash = window.location.hash.substring(1)
    if (hash) {
      const result = hash.split('&').reduce((result: any, item) => {
        const parts = item.split('=')
        result[parts[0]] = decodeURIComponent(parts[1])
        return result
      }, {})
      if (result.access_token) {
        window.opener.postMessage(
          {
            type: 'GOOGLE_AUTH_SUCCESS',
            access_token: result.access_token
          },
          window.location.origin
        )
        // window.close()
      } else {
        console.error('No access token found in the callback URL')
      }
    }
  }, [])

  return <div></div>
}

export default GoogleAuthCallback
