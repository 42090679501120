import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useCallback
} from 'react'
import { format } from 'date-fns'
import { apiRequest } from 'utils/api'
import { useEvents } from 'hooks/useEvents'

interface User {
  _id: string
  status: string
  name: string
  role: string
  country: string
  startDate: string
  avgHours: number
  rate: number
  avgYear: number
  isOnVacation: boolean
}

interface PeopleContextType {
  isLoading: boolean
  people: User[]
  onboarding: User[]
  active: User[]
  alumni: User[]
}

export const PeopleContext = createContext<PeopleContextType | undefined>(
  undefined
)

const API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL

export const PeopleProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [peopleData, setPeopleData] = useState({
    people: [] as User[],
    onboarding: [] as User[],
    active: [] as User[],
    alumni: [] as User[]
  })
  const { events } = useEvents()

  const fetchPeople = useCallback(async () => {
    setIsLoading(true)
    const [usersResponse] = await Promise.all([
      apiRequest<User[]>({
        baseUrl: API_BASE_URL,
        method: 'get',
        url: '/users'
      }),
    ])

    if (usersResponse) {
      const data = usersResponse.map((person: any) => ({
        ...person,
        startDate: person.startDate
          ? format(new Date(person.startDate), 'MMM dd, yyyy')
          : 'N/A',
          isOnVacation: isUserOnVacationToday(person._id)
      }))

      setPeopleData({
        people: data,
        onboarding: data.filter(
          (person: User) => person.status === 'onboarding'
        ),
        active: data.filter((person: User) => person.status === 'active'),
        alumni: data.filter((person: User) => person.status === 'alumni')
      })
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchPeople()
  }, [fetchPeople])

  const today = new Date()
  const eventsToday = events.filter(event =>
    new Date(event.start.getFullYear(), event.start.getMonth(), event.start.getDate()).getTime() <= today.getTime() &&
    new Date(event.end.getFullYear(), event.end.getMonth(), event.end.getDate()).getTime() >= today.getTime()
  ) as any[]

  const isUserOnVacationToday = (userId: string) => eventsToday.some(event => event.userId === userId)


  return (
    <PeopleContext.Provider
      value={{
        ...peopleData,
        isLoading
      }}
    >
      {children}
    </PeopleContext.Provider>
  )
}
