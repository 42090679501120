import React from 'react'
import { useAuth } from '../hooks/useAuth'
import { format } from 'date-fns'
import { formatName } from 'utils/util'

const Profile: React.FC = () => {
  const { user } = useAuth()

  const profileSections = [
    {
      label: 'Personal Information',
      items: [
        { key: 'Role', value: user.role },
        { key: 'Country', value: user.country },
        { key: 'Email', value: user.email },
        {
          key: 'Start Date',
          value: format(new Date(user.startDate), 'MMM dd, yyyy')
        }
      ]
    },
    {
      label: 'Work Information',
      items: [
        { key: 'Rate', value: `$${user.rate}/hour` },
        { key: 'Average Hours', value: `${user.avgHours} hours/week` },
        { key: 'Avg. Yearly', value: `$${user.avgYear.toLocaleString()}/year` }
      ]
    }
  ]

  return (
    <div className="border-2 border-gray-200 rounded-2xl overflow-hidden">
      <table className="min-w-full">
        <tbody className="mb-0">
          <tr>
            <td className="p-6">
              <h1 className="text-2xl font-bold mb-6">
                {formatName(user.name)}
              </h1>
              <div className="flex">
                {profileSections.map((section, sectionIndex) => (
                  <div key={`section-${sectionIndex}`} className="flex-1">
                    {section.items.map((item, itemIndex) => (
                      <div
                        key={`item-${sectionIndex}-${itemIndex}`}
                        className={itemIndex !== 0 ? 'mt-6' : ''}
                      >
                        <h3 className="text-ms font-bold text-gray-900">
                          {item.key}
                        </h3>
                        <p className="text-sm text-gray-900">{item.value}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Profile
