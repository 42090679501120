import React, { useState, useCallback } from 'react'
import {
  Calendar as BigCalendar,
  dateFnsLocalizer,
  View,
  Views
} from 'react-big-calendar'
import {
  format,
  parse,
  startOfWeek,
  getDay,
  addDays,
  subDays,
  endOfWeek
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import TabNavigation from 'components/TabNavigation'
import EventModal from 'components/EventModal'
import { useEvents } from 'hooks/useEvents'
import { useCalendar } from 'hooks/useCalendar'
import { useModal } from 'hooks/useModal'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './Calendar.css'

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: {
    'en-US': enUS
  }
})

const Calendar: React.FC = () => {
  const [view, setView] = useState<View>(Views.MONTH)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [activeNavigation, setActiveNavigation] = useState('Today')
  const { events } = useEvents()
  const { components, formats, handles } = useCalendar()
  const { isModalOpen, closeModal, props } = useModal()

  const viewTabs = ['Month', 'Agenda']
  const navigationTabs = ['Back', 'Today', 'Next']

  const onViewChange = (tab: string) => {
    setView(Views[tab.toUpperCase() as keyof typeof Views])
  }

  const onNavigationChange = useCallback(
    (action: string) => {
      setActiveNavigation(action)
      switch (action) {
        case 'Back':
          setCurrentDate((prevDate) => {
            if (view === Views.MONTH) {
              return subDays(prevDate, 30)
            } else if (view === Views.AGENDA) {
              return subDays(prevDate, 31)
            }
            return prevDate
          })
          break
        case 'Today':
          setCurrentDate(new Date())
          break
        case 'Next':
          setCurrentDate((prevDate) => {
            if (view === Views.MONTH) {
              return addDays(prevDate, 30)
            } else if (view === Views.AGENDA) {
              return addDays(prevDate, 31)
            }
            return prevDate
          })
          break
      }
    },
    [view]
  )

  const getActiveView = (currentView: View): string => {
    return (
      currentView.charAt(0).toUpperCase() + currentView.slice(1).toLowerCase()
    )
  }

  const getToolbarLabel = () => {
    switch (view) {
      case Views.MONTH:
        return format(currentDate, 'MMMM yyyy')
      case Views.AGENDA:
        const start = startOfWeek(currentDate)
        const end = endOfWeek(addDays(currentDate, 30))
        return `${format(start, 'MMMM d, yyyy')} - ${format(
          end,
          'MMMM d, yyyy'
        )}`
      default:
        return ''
    }
  }

  return (
    <div className="overflow-x-hidden">
      <EventModal
        isOpen={isModalOpen}
        onClose={closeModal}
        start={props.start}
        end={props.end}
      />
      <div className="flex justify-between items-center">
        <TabNavigation
          tabs={viewTabs}
          activeTab={getActiveView(view)}
          onTabChange={onViewChange}
        />
        <div className="text-xl font-semibold mb-6">{getToolbarLabel()}</div>
        <TabNavigation
          tabs={navigationTabs}
          activeTab={activeNavigation}
          onTabChange={onNavigationChange}
        />
      </div>

      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: view === 'agenda' ? 'unset' : 'calc(100vh - 250px)' }}
        selectable
        onSelectSlot={handles.onSelectSlot}
        onSelectEvent={handles.onSelectEvent}
        popup={true}
        getDrilldownView={handles.getDrilldownView}
        view={view}
        onView={setView}
        date={currentDate}
        onNavigate={(date) => setCurrentDate(date)}
        className="custom-calendar w-full overflow-hidden text-sm"
        components={components}
        formats={formats}
      />
    </div>
  )
}

export default Calendar
