import { useContext } from "react";
import { PeopleContext } from "../contexts/PeopleContext";

export const usePeople = () => {
  const context = useContext(PeopleContext);
  if (context === undefined) {
    throw new Error('usePeople must be used within an PeopleProvider');
  }
  return context;
};
