import React from 'react';

const MobileMessage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#1c1c1c] p-4 text-center">
      <img
        src="/flexile-logo.svg"
        alt="Flexile"
        className="h-16 mb-8 invert"
      />
      <h1 className="text-2xl font-bold text-white mb-4">
        Mobile version under development
      </h1>
      <p className="text-gray-300">
        Please access Flexile via the Desktop version for the best experience.
      </p>
    </div>
  );
};

export default MobileMessage;
